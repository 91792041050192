<template>
  <span
    class="font-bold rounded-sm text-center align-middle cursor-pointer text-whitesmoke px-2 py-1"
    :class="{
      'bg-slate-600': type === 'default',
      'bg-info': type === 'info',
      'bg-success': type === 'success',
      'bg-yellow-300/75 !text-black': type === 'warning',
      'bg-danger': type === 'danger',
      'bg-error': type === 'error',
    }"
    @click="$emit('click')"
  >
    <span>
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  name: "JoszakiTag",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
      validator: function (value) {
        // The value must match one of these strings
        return [
          "default",
          "info",
          "success",
          "warning",
          "danger",
          "error",
        ].includes(value);
      },
    },
  },
};
</script>
