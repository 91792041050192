var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-bold rounded-sm text-center align-middle cursor-pointer text-whitesmoke px-2 py-1",class:{
    'bg-slate-600': _vm.type === 'default',
    'bg-info': _vm.type === 'info',
    'bg-success': _vm.type === 'success',
    'bg-yellow-300/75 !text-black': _vm.type === 'warning',
    'bg-danger': _vm.type === 'danger',
    'bg-error': _vm.type === 'error',
  },on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }