<template>
  <div class="joszaki-data-table flex flex-col gap-2">
    <div v-if="!!search" class="flex flex-row gap-2">
      <JoszakiInput
        v-model="searchString"
        icon-left="search"
        class="flex-1"
        :show-error-msg="false"
        :placeholder="search.placeholder"
        @input="onSearchInput"
      />
    </div>
    <div class="flex-1 overflow-scroll max-h-[80vh] relative">
      <table
        class="max-h-full p-4 border-spacing-2 w-full relative border border-gray-300"
      >
        <thead class="bg-primary sticky top-0 z-50">
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="index"
              class="p-2 text-white"
              :class="{ 'cursor-pointer': column.orderable }"
              @click="onColumnHeaderClick(column)"
            >
              {{ column.label }}
              <IconComponent
                v-if="column.orderable"
                :class="{ 'opacity-25': orderBy !== column.name }"
                :icon="order === 'asc' ? 'angle-down' : 'angle-up'"
                class="inline w-5"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="fetchState.pending">
            <tr
              v-for="i in pageSize"
              :key="i"
              class="[&:nth-child(even)]:bg-primary-lightest/30 hover:!bg-primary-lightest"
            >
              <td v-for="(_column, index) in columns" :key="index" class="p-4">
                <content-placeholders>
                  <content-placeholders-text :lines="1" class="[&>*]:!mb-0" />
                </content-placeholders>
              </td>
            </tr>
          </template>
          <tr v-else-if="fetchState.error">
            <td :colspan="columns.length" class="p-2 text-error">
              {{ $t("error.unknown") }}
              <br />
              {{ fetchState.error?.message }}
            </td>
          </tr>
          <tr v-else-if="items.length === 0">
            <td :colspan="columns.length" class="p-2">
              {{ $t("conductor.dataTable.noData") }}
            </td>
          </tr>
          <tr
            v-for="row in items"
            :key="`row-${row.id}`"
            class="[&:nth-child(even)]:bg-primary-lightest/30 hover:!bg-primary-lightest"
          >
            <td
              v-for="(column, colIndex) in columns"
              :key="`column-${colIndex}`"
              class="p-2 align-middle truncate max-w-[200px]"
            >
              <template v-if="!$scopedSlots[`column-${column.name}`]">
                {{
                  column.formatter ? column.formatter(row) : row[column.name]
                }}
              </template>
              <slot :name="`column-${column.name}`" :row="row" />
            </td>
          </tr>
        </tbody>
        <tfoot v-if="pagination" class="bg-primary sticky bottom-0 z-50">
          <tr>
            <td :colspan="columns.length" class="p-2 text-white">
              {{
                $t("conductor.dataTable.total", {
                  total: pagination?.total,
                  pages: pagination?.last,
                })
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="pt-2 flex flex-col md:flex-row justify-between">
      <JoszakiDropdown
        v-model="pageSize"
        :items="pageSizeOptions"
        @select="onPageSizeChange"
      />
      <PaginationComponent
        v-if="pagination"
        :pagination-info="pagination"
        :increment="4"
      />
    </div>
  </div>
</template>

<script>
import {
  ref,
  useFetch,
  useRoute,
  useRouter,
  useContext,
  toRefs,
} from "@nuxtjs/composition-api";
import { watch } from "vue";
import PaginationComponent from "~/components/_refactored/common/PaginationComponent.vue";
import { debounce } from "~/helpers/debounce";

export default {
  components: { PaginationComponent },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    search: {
      type: Object,
      default: () => null,
    },
    load: {
      type: Function,
      required: true,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { filter } = toRefs(props);
    const route = useRoute();
    const router = useRouter();
    const { i18n } = useContext();

    const pageSizeOptions = [15, 30, 50, 100, 250].map((pageSize) => ({
      value: pageSize,
      label: i18n.t("conductor.dataTable.perPage", { pageSize }),
    }));

    const query = route.value.query;
    const orderBy = ref(query?.orderBy ?? "id");
    const order = ref(query?.order ?? "asc");
    const searchString = ref(query?.search ?? "");
    const page = ref(Number(query?.page ?? 1));
    const pageSize = ref(Number(query?.pageSize ?? 15));
    const items = ref([]);
    const pagination = ref(null);

    const { fetch, fetchState } = useFetch(async () => {
      items.value = [];
      const response = await props.load({
        page: page.value,
        pageSize: pageSize.value,
        search: searchString.value,
        orderBy: orderBy.value,
        order: order.value,
        filter: filter.value,
      });

      pagination.value = response.pagination;
      items.value = response.items;
    });

    watch(
      () => route.value.query,
      (query) => {
        console.info("route changed", query);
        page.value = Number(query?.page ?? 1);
        fetch();
      }
    );

    watch(filter, () => {
      fetch();
    });

    const onColumnHeaderClick = (column) => {
      if (!column.orderable) {
        return;
      }

      if (orderBy.value === column.name) {
        order.value = order.value === "asc" ? "desc" : "asc";
      } else {
        orderBy.value = column.name;
        order.value = "asc";
      }

      router.push({
        query: {
          ...route.value.query,
          orderBy: orderBy.value,
          order: order.value,
          page: 1,
        },
      });
    };

    const onSearchInput = debounce(async function (search) {
      await router.push({
        query: {
          ...route.value.query,
          search,
          page: 1,
        },
      });
    }, 500);

    const onPageSizeChange = (pageSize) => {
      router.push({
        query: {
          ...route.value.query,
          pageSize: pageSize.value,
          page: 1,
        },
      });
    };

    return {
      pageSizeOptions,
      orderBy,
      order,
      searchString,
      page,
      pageSize,
      items,
      pagination,
      fetch,
      fetchState,
      onColumnHeaderClick,
      onSearchInput,
      onPageSizeChange,
    };
  },
  fetchOnServer: false,
};
</script>
