import { useContext, useRoute, useRouter } from "@nuxtjs/composition-api";
import { useAuthStore } from "../store";
import { useCheckMismatch } from "./useCheckMismatch";
import { useCookieManager } from "./useCookieManager";
import { useUserStore } from "~/stores/user";

export function useLogin() {
  const authStore = useAuthStore();
  const route = useRoute();
  const router = useRouter();
  const cookieManager = useCookieManager();
  const userStore = useUserStore();
  const checkMismatch = useCheckMismatch();

  const { $mutate, localePath } = useContext();

  async function loginRequest(email, password) {
    const LOGIN_MUTATION = await import("../mutations/login.graphql");
    return $mutate(LOGIN_MUTATION, {
      email,
      password,
      campaignsCookie: cookieManager.getCampaignsCookie(),
    });
  }

  async function firstLoginRequest(credentials) {
    const FIRST_LOGIN_MUTATION = await import(
      "../mutations/firstLoginMutation.graphql"
    );
    return $mutate(FIRST_LOGIN_MUTATION, {
      email: credentials.email,
      newPassword: credentials.password,
      emailConfirmationCode: credentials.emailConfirmationCode,
      campaignsCookie: cookieManager.getCampaignsCookie(),
    });
  }

  async function loginToOneSignal(resp) {
    if (
      window?.OneSignal &&
      typeof window?.OneSignal.login === "function" &&
      resp.isMate
    ) {
      await window?.OneSignal.login(resp.personId);
    }
  }

  async function loadNextPage() {
    const tenders = await userStore.getMyTenderFeed();

    const callbackUrl = route.value.query["callback-url"];

    if (callbackUrl) {
      router.push(decodeURIComponent(callbackUrl));
    } else if (userStore.me.isCustomer) {
      if (tenders.length === 0) {
        router.push(localePath({ name: "requestForTender" }));
      } else {
        router.push(localePath({ name: "myTenders" }));
      }
    } else if (userStore.me.isMate) {
      router.push(localePath({ name: "buyTendersPage" }));
    }
  }

  /**
   *
   * @param {Object} credentials - The credentials to login
   * @param {string} credentials.email
   * @param {string} credentials.password
   * @param {string} [credentials.emailConfirmationCode] - Only in case of first login
   */
  return async function login(credentials) {
    let resp;
    if (credentials.emailConfirmationCode) {
      resp = await firstLoginRequest(credentials);
    } else {
      resp = await loginRequest(credentials.email, credentials.password);
    }

    checkMismatch(resp.personId, resp.token);

    await authStore.loginWithToken(resp.token);

    userStore.updateLastPerson();

    await loginToOneSignal(resp);

    await loadNextPage(resp);
  };
}
