import Vue from "vue";

import { formatNumber, parseNumber } from "libphonenumber-js";

export default function (_ctx, inject) {
  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return "";
    }

    const { number, countryCode } = phoneNumber;

    if (!number) {
      return "";
    }
    const iso = countryCode?.iso || "HU";

    const parsedNumber = parseNumber(number, iso.trim().toUpperCase());
    return formatNumber(parsedNumber, "International", {
      formatExtension(number, extension) {
        return `${number} / ${extension}`;
      },
    });
  }

  function getPhoneNumberLink(phoneNumber) {
    if (!phoneNumber) {
      return "";
    }

    const { number, countryCode } = phoneNumber;

    if (!number) {
      return "";
    }
    const iso = countryCode?.iso || "HU";

    const parsedNumber = parseNumber(number, iso.trim().toUpperCase());
    return formatNumber(parsedNumber, "RFC3966");
  }

  const support = {
    email: process.env.SUPPORT_MAIL,
    phone: formatPhoneNumber({
      number: process.env.SUPPORT_PHONE,
      countryCode: process.env.SUPPORT_PHONE_COUNTRY,
    }),
    emailLink: `mailto:${process.env.SUPPORT_MAIL}`,
    phoneLink: getPhoneNumberLink({
      number: process.env.SUPPORT_PHONE,
      countryCode: process.env.SUPPORT_PHONE_COUNTRY,
    }),
  };

  const helpers = {
    topDomain: process.env.TOP_DOMAIN,
    support,
    socialNetworkUrls: {
      facebook: process.env.FACEBOOK_PAGE_URL,
      blog: process.env.BLOG_URL,
      twitter: process.env.TWITTER_PAGE_URL,
      pinterest: process.env.PINTEREST_PAGE_URL,
      tiktok: process.env.TIKTOK_PAGE_URL,
    },
    /**
     * Add a parameter to a passed in URL
     *
     *  @example addToUrl("http://joszaki.space","komuves") => "http://komuves.joszaki.space"
     *
     * addToUrl("http://joszaki.space",,"budapest") => "http://joszaki.space/budapest"
     * addToUrl("http://joszaki.space","komuves","budapest") => "http://komuves.joszaki.space/budapest"
     *
     * @param {string} url
     * @param {string} subdomain - subdomain to add
     * @param {string|string[]} parameters
     */
    addToUrl(url, subdomain, ...parameters) {
      let newUrl = url;
      if (subdomain) {
        newUrl = newUrl.replace("://", `://${subdomain}.`);
      }
      return [newUrl, ...parameters].join("/");
    },
    addSearchParamsToUrl(url, params) {
      const urlSearchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          urlSearchParams.append(key, value);
        }
      });

      return new URL(`${url}?${urlSearchParams}`).href;
    },
    getReferencePhoto(name, size = "normal") {
      return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_REFERENCE_BUCKET}/${size}/${name}`;
    },
    getProfilePictureThumbnailUrl(id) {
      return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_CONVERTED_BUCKET}/${id}_256.jpg`;
    },
    getProfilePictureOriginalUrl(id) {
      return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_BUCKET}/${id}`;
    },
    getBlogPhotoUrl(id, size = "thumbnail") {
      return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_BLOG_BUCKET}/${size}/${id}`;
    },
    noImage(e) {
      e.target.src = require("../assets/default-avatar.jpg");
    },
    getMainDomain() {
      const hostname = new URL(this.topDomain).hostname;
      if (hostname) {
        const parts = hostname.split(".");

        while (parts.length > 2) {
          parts.shift();
        }
        return parts.join(".");
      }
      return "";
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    stringToSlug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      const from = "àáäâèéëêìíïîòóöőôùúüűûñç·/_,:;";
      const to = "aaaaeeeeiiiiooooouuuuunc------";
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    formatNumber(number) {
      return number.toString().replace(/(.)(?=(\d{3})+$)/g, "$1 ");
    },
    localeFormatNumber(number, precision, locale = "hu-HU") {
      return number.toLocaleString(locale, {
        maximumFractionDigits: precision,
      });
    },
    getPhoneNumberExtension(number) {
      if (!number) {
        return "";
      }
      const isVirtual = number.includes(",");

      if (isVirtual) {
        return number.split(",")[1];
      }
      return "";
    },
    formatPhoneNumber,
    getPhoneNumberLink,
    formatPrice(number) {
      return this.formatNumber(number) + " Ft";
    },
    normalizeInput(input) {
      return input
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036F]/g, "");
    },
    isHeroku() {
      return process.env.HEROKU === "true";
    },
    isDev() {
      return process.env.NODE_ENV === "development";
    },
    arrayRange(start, stop, step) {
      return Array.from(
        { length: (stop - start) / step + 1 },
        (_value, index) => start + index * step
      );
    },
    /**
     * @param {String} professionSeoName
     * @returns true if profession should be redirected from subdomain to rootdomain
     */
    is301(profession) {
      if (this.isHeroku()) {
        return true;
      }
      return profession.urlType === "folder";
    },
    getFromLocalStorage(key, defaultObj = "{}") {
      return JSON.parse(localStorage.getItem(key) || defaultObj);
    },
    setToLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    // Cookie list tools
    addPersonToCookieList(personId, cookieName) {
      const existingList = this.getFromLocalStorage(cookieName, "[]");
      const newList = [...new Set([...existingList, personId])];
      this.setToLocalStorage(cookieName, newList);
    },
    removePersonFromCookieList(personId, cookieName) {
      const existingList = this.getFromLocalStorage(cookieName, "[]");
      const newList = existingList.filter((id) => id !== personId);
      this.setToLocalStorage(cookieName, newList);
    },
    isPersonInCookieList(personId, cookieName) {
      const existingList = this.getFromLocalStorage(cookieName, "[]");
      return !!existingList.find((id) => id === personId);
    },
    getIsoString(year, month, day, hour = 0, minute = 0, second = 0) {
      return new Date(
        Date.UTC(year, month, day, hour, minute, second)
      ).toISOString();
    },
  };

  Vue.prototype.$helpers = helpers;
  inject("helpers", helpers);
}
